import Vue from 'vue';
import { seo } from '../../app.config.json'
import Util from '@/controllers/Util.controller'
/**
 * @steps 3;
 * 1 - Entrou na página do Carrinho
 * 2 - Iniciou o Pagamento
 * 3 - Finalizou o Pagamento
 */

Vue.prototype.$google = new Vue ({
    data() {
        return{
            gtmKey: seo.gtm,
            $util: new Util()
        }
    },
    methods: {

        async getBrowserUUID() {
            var navigator_info = window.navigator;
            var screen_info = window.screen;
            var uid = navigator_info.mimeTypes.length;
            uid += navigator_info.userAgent.replace(/\D+/g, '');
            uid += navigator_info.plugins.length;
            uid += screen_info.height || '';
            uid += screen_info.width || '';
            uid += screen_info.pixelDepth || '';
            return this.uuidTohex(uid)
        },
    
        uuidTohex(uuid) {
            if(uuid){
                let uid = uuid.toString().replace(/\D+/g, "");
                if(uid.length < 40){
                    for (let i = 0; i < 40; i++) {
                        uid += 0;
                    }
                }
                uid = uid.substring(0, 40)
                return uid.toString()
            }
            return false
        },

        mobile() { // TODO: mobile
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
        
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },

        async init() {
            // https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce?hl=pt_br

            var varCounter = 0;
            const id = false

            let gtm = document.createElement("script");
                gtm.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.gtmKey}`)
                gtm.setAttribute('async', true)
            document.head.prepend(gtm);

            let dataGtm = document.createElement("script");
                dataGtm.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
        
                    gtag('config', '${this.gtmKey}', { 
                        'user_id': '${id}',
                        'debug_mode': false,
                        'page_title': '${document.title}',
                        'currency': 'BRL',
                    });
                `
            document.head.append(dataGtm);
            
            // const self = this
            let time = setInterval(function(){
                if(varCounter >= 10){
                    clearInterval(time);
                }
                
                if(typeof window.gtag != 'undefined'){
                    clearInterval(time);

                    window.gtag('set', 'allow_ad_personalization_signals', false );
                    window.gtag('set', 'allow_google_signals', false);

                    console.warn('GA4 implementado')
                }

                varCounter++
            }, 1000)

        },

        login(metodo = 'Superingresso', tipo = 'Público geral'){
            window.gtag('event', 'login', {'method': metodo});
            window.gtag('event', 'sign_in', { 'send_to': tipo });
        },

        paginasView(){
            if(typeof window.gtag == 'undefined'){ return }
            const root = document.getElementById('app').__vue__
            window.gtag('config', this.gtmKey, {
                'page_title' : root.$route.name,
                'page_path': root.$route.path
            });
        },

        tratarProdutos(produtos = []){

            let items = []

            produtos.forEach((item) => {
                
                let i = {
                    "id": item.idapresentacao,
                    "name": item.dsceven + ' - ' + item.dscproduto,
                    "list_name": item.dscproduto,
                    "brand": item.dsceven,
                    "category": "Tickets/Online",
                    "quantity": 1,
                    "price": Number(item.total).toFixed(2)
                }

                if(JSON.stringify(items).toString().indexOf(item.idapresentacao) != -1){
                    items.forEach(update => {
                        if(update.id == item.idapresentacao){
                            update.quantity = Number(update.quantity) + 1
                        }
                    })
                } else {
                    items.push(i)
                }
                
            })

            items.forEach((item, index) => {
                item.list_position = index
            })

            return items
        },

        purchaseData(produtos = []){
            let total = 0
            let taxa  = 0

            produtos.forEach((item) => {
                total += Number(item.total)
                taxa += Number(item.taxa)
            })

            return { total, taxa }
        },

        addCarrinho(produtos = []){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'view_item_list',  { "items": produtos });
            window.gtag('event', 'select_content',  { "content_type": "tickets", "items": produtos });
            window.gtag('event', 'add_to_cart',  { "items": produtos });
        },

        removeCarrinho(produtos = []){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'remove_from_cart',  { "items": produtos });
        },

        checkoutStepOne(produtos = [], cupom = ''){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'begin_checkout',  { "items": produtos, "coupon": cupom });
            console.warn('Checkout Begin')
        },

        checkoutStepPagamento(produtos = [], cupom = ''){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'checkout_progress',  { "items": produtos, "coupon": cupom });
            console.warn('Checkout Pagamento')
        },

        checkoutStepFinalizarCarrinho(produtos = [], idmovimentacao = new Date().getTime(), cupom = ''){
            if(typeof window.gtag == 'undefined'){ return }
            const config     = this.purchaseData(produtos)
            const plataforma = this.mobile() ? 'Mobile': 'Desktop'
            produtos = this.tratarProdutos(produtos)

            window.gtag('event', 'set_checkout_option', {
                "checkout_step": 1,
                "checkout_option": "shipping method",
                "value": 3
            });

            window.gtag('event', 'purchase', {
                "transaction_id": idmovimentacao,
                "affiliation": `Superingresso - ${plataforma}`,
                "value": config.total,
                "currency": "BRL",
                "tax": config.data,
                "shipping": 0,
                "coupon": cupom,
                "items": produtos
            });
            console.warn('Finalizando Purchase')
            /* setTimeout(() => { comentado pois dava erro de apresentação não encontrada no checkout
                this.$util.go('HubPedidos')
            }, 1000) */
        },

        reembolsoParcial(produtos = [], idmovimentacao = new Date().getTime()){
            if(typeof window.gtag == 'undefined'){ return }
            const config = this.purchaseData(produtos)
            produtos = this.tratarProdutos(produtos)

            window.gtag('event', 'purchase', {
                "transaction_id": idmovimentacao,
                "affiliation": `Superingresso - ${plataforma}`,
                "value": config.total,
                "currency": "BRL",
                "tax": config.data,
                "shipping": 0,
                "items": produtos
            });
        }
    }
});

Vue.prototype.$google.init()