import Vue from 'vue'
Vue.prototype.$facebook = new Vue ({
    data() {
        return { }
    },
    methods: {

        init(){
            let script = document.createElement("script");
                script.innerHTML = `
                    !function (f, b, e, v, n, t, s) {
                        if (f.fbq) return; n = f.fbq = function () {
                            n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                        };
                        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                        n.queue = []; t = b.createElement(e); t.async = !0;
                        t.src = v; s = b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t, s)
                    }(window, document, 'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1367413330122163');
                    fbq('track', 'PageView');
                `
            document.head.append(script);

            let img = document.createElement("img")
            img.src = "https://www.facebook.com/tr?id=1367413330122163&ev=PageView&noscript=1";
            img.append("img");
            img.style = "display: none;"

            let noscript = document.createElement("noscript").appendChild(img);
            document.head.prepend(noscript);
        },

        pesquisa(search){
            if(window.fbq){
                window.fbq('track', 'Search', {
                    search_string: search
                });
            }
        },

        visualizacao(valor){
            if(window.fbq){
                window.fbq('track', 'ViewContent', {
                    value: valor,
                    currency: 'BRL'
                });
            }
        },

        addCarrinho(){
            let script = document.createElement("script");
                script.innerHTML = `
                    !function (f, b, e, v, n, t, s) {
                        if (f.fbq) return; n = f.fbq = function () {
                            n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                        };
                        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                        n.queue = []; t = b.createElement(e); t.async = !0;
                        t.src = v; s = b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t, s)
                    }(window, document, 'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1367413330122163');
                    fbq('track', 'AddToCart');
                `
            document.head.append(script);

            let img = document.createElement("img")
            img.src = "https://www.facebook.com/tr?id=1367413330122163&ev=PageView&noscript=1";
            img.append("img");
            img.style = "display: none;"

            let noscript = document.createElement("noscript").appendChild(img);
            document.head.prepend(noscript);
        },

        listaDesejo(item){
            if(window.fbq){
                window.fbq('track', 'AddToWishlist', {
                    content_ids: [item.idproduto],
                    content_type: item.dscproduto,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        checkout(){
            let script = document.createElement("script");
                script.innerHTML = `
                    !function (f, b, e, v, n, t, s) {
                        if (f.fbq) return; n = f.fbq = function () {
                            n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                        };
                        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                        n.queue = []; t = b.createElement(e); t.async = !0;
                        t.src = v; s = b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t, s)
                    }(window, document, 'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1367413330122163');
                    fbq('track', 'InitiateCheckout');
                `
            document.head.append(script);

            let img = document.createElement("img")
            img.src = "https://www.facebook.com/tr?id=1367413330122163&ev=PageView&noscript=1";
            img.append("img");
            img.style = "display: none;"

            let noscript = document.createElement("noscript").appendChild(img);
            document.head.prepend(noscript);
        },

        addInfoPgto(){
            if(window.fbq){
                window.fbq('track', 'AddPaymentInfo');
            }
        },

        concluirCompra(){
            let script = document.createElement("script");
                script.innerHTML = `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1367413330122163');
                    fbq('track', 'Purchase');
                `
            document.head.append(script);

            let img = document.createElement("img")
            img.src = "https://www.facebook.com/tr?id=1367413330122163&ev=PageView&noscript=1";
            img.append("img");
            img.style = "display: none;"

            let noscript = document.createElement("noscript").appendChild(img);
            document.head.prepend(noscript);
        },

        cadastro(item){
            if(window.fbq){
                window.fbq('track', 'Lead', {
                    content_name: item.nome,
                    content_category: item.categoria,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        completarCadastro(){
            if(window.fbq){
                window.fbq('track', 'CompleteRegistration');
            }
        },

        eventoPersonalizado(evento, options = { name: 'Cartão de Crédito', value: 0 }){
            if(window.fbq){
                window.fbq('track', evento, {
                    name: options.name,
                    value: options.value,
                    currency: 'BRL'
                });
            }
        }
    }
});

setTimeout(() => {
    Vue.prototype.$facebook.init()
}, 1500)

