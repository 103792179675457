//** IMPORT ALL CODE TO SCRIPT IN GOOGLE TAG MANAGER */
//================================================

var lib = "sdk-cookies-aceite";
var version = "v2.0.0";
var cookiesAceiteImport = document.createElement("script");
cookiesAceiteImport.type = "text/javascript";
cookiesAceiteImport.async = true;
cookiesAceiteImport.src =
    "https://sdk.imply.com/" + lib + "-" + version + ".min.js";

// =================================================
/** CUSTOMIZE AND CALL COOKIES ACEITE **/

function startFlowCookiesAceite() {
    var urlParams = new URLSearchParams(window.location.search);
    var urlCookies = "/pt/politicas/cookies";
    const locale = localStorage.getItem("locale");
    locale == "en"
        ? (urlCookies = "/en/politics/cookies")
        : locale == "es"
        ? (urlCookies = "/es/politicas/cookies")
        : (urlCookies = "/pt/politicas/cookies");
    /** PARAMETERS */
    var params = {
        /** BAR AND MODAL */
        //A CRIAR A PÁGINA DE POLÍTICAS
        link_cookies: urlCookies,
        /** set button color*/
        cor_botao: "rgba(182, 25, 24, 1)",
        /** set button text color */
        //cor_texto_botao: '#004674',
        /** set bar and modal background color */
        //cor_fundo: '#004674',
        /** set text color */
        //cor_texto: '#fff',
        /** MODAL ONLY*/
        /** set title color */
        //cor_titulo: "#fedc19",
        /** set primary color to highlight items from lists(cookies types, cookies array) */
        cor_primaria: "rgba(182, 25, 24, 0.3)",
        /** Set secondary color to highlight items from lists (cookies types, cookies array) after selected */
        cor_detalhes: "rgba(182, 25, 24, 0.5)",
        cor_secundaria: "rgba(182, 25, 24, 0.5)",
        /** set buttons text color to "settings", "details" and "back" */
        //cor_detalhes: '#fedc19',
        cor_detalhes: "rgba(182, 25, 24, 0.5)",
        /** set test color to "powered by" */
        //cor_desenvolvido_por: "#fff",
        /** set name in powered by */
        //nome_desenvolvido_por: 'Imply',
        /** set border color to modal */
        //cor_borda: 'rgba(0, 79, 160, 0.9)',
        /** set close icon color */
        //cor_fechar_icone: '#fedc19',
        /** set image url */

        image_src:
            "https://internacionalcloudfront.eleventickets.com/logo_inter_100x100.jpg",

        /** set alt text */
        //image_alt: 'Imagem teste',
        /** set language */
        lang: urlParams.get("lang") ? urlParams.get("lang").substr(0, 2) : "pt",

        /** DATA */
        meus_cookies: [
            {
                nome_cookies: "Cookie_analitico",
                traducao: {
                    pt_BR: {
                        nome: "COOKIES ANALÍTICOS",
                        descricao:
                            "Os cookies analíticos dedicam-se a registrar os dados de uso do website para que possamos aprimorá-lo futuramente, como os dados de índice de audiência da página e de fontes de tráfego. Tais dados nos ajudam a saber quais são as páginas mais e menos populares e a verificar como os Usuários se movimentam no website. O Grupo Imply® utiliza cookies analíticos próprios e também do Google Analytics para geração de dados estatísticos agregados de uso. Ressalta-se que as informações estatísticas geradas para o Grupo Imply® são agregadas – ou seja, não há individualização.",
                    },
                    es_ES: {
                        nome: "COOKIES ANALÍTICAS",
                        descricao:
                            "Las cookies analíticas están dedicadas a registrar datos de uso del sitio web para que podamos mejorarlo en el futuro, como los datos del índice de audiencia de la página y las fuentes de tráfico. Dichos datos nos ayudan a saber cuáles son las páginas más y menos populares y a comprobar cómo se mueven los Usuarios por el sitio web. El Grupo Imply® utiliza cookies analíticas propias y también Google Analytics para generar datos estadísticos agregados de uso. Se destaca que la información estadística generada para el Grupo Imply® es agregada, es decir, no hay individualización.",
                    },
                    en_US: {
                        nome: "ANALYTICAL COOKIES",
                        descricao:
                            "Analytical cookies are dedicated to recording website usage data so that we can improve it in the future, such as page audience index data and traffic sources. Such data helps us to know which are the most and least popular pages and to check how Users move around the website. The Imply® Group uses its own analytical cookies and also Google Analytics to generate aggregated statistical data of use. It is emphasized that the statistical information generated for the Imply® Group is aggregated – that is, there is no individualization.",
                    },
                },
                itens: [
                    {
                        nome: "_Cook_Acc_",
                        valor: "U2FsdGVkX199Gqhip/WiEbiv8uRf5MZgCEOebry6k+yXgZssLzvgfFaHKP7FBe8mXiy1sKpNr9kzdPz1EC2Xs51uwOJYpOrZCvfOSK664QbawxQJZ3Cfvlm+ELJxt+ML",
                        descricao: {
                            pt_BR: "",
                            es_ES: "",
                            en_US: "",
                        },
                    },
                ],
            },
            {
                nome_cookies: "Cookie_Personalizados",
                traducao: {
                    pt_BR: {
                        nome: "COOKIES COMPORTAMENTAIS/MARKETING/TERCEIROS",
                        descricao:
                            "Os cookies comportamentais/marketing/terceiros servem para traçar um lastro de preferências do Usuário baseado em dados relativos à sua navegação, auxiliando a exibição e criação de anúncios personalizados. Esses cookies podem ser ativados tanto no nosso website quanto nas plataformas dos nossos parceiros de publicidade (como Facebook, Google e LinkedIn). Eles são utilizados para mensurar e veicular publicidade no ambiente digital de forma personalizada e podem ajudar a mostrar anúncios online de produtos que podem ser do seu interesse. Caso a utilização desses cookies não seja permitida, você receberá menos publicidade das nossas novidades.",
                    },
                    es_ES: {
                        nome: "COOKIES DE COMPORTAMIENTO/MARKETING/TERCEROS",
                        descricao:
                            "Las cookies comportamentales/de marketing/de terceros sirven para rastrear las preferencias del Usuario en base a datos relativos a su navegación, ayudando a mostrar y crear anuncios personalizados. Estas cookies se pueden activar tanto en nuestro sitio web como en las plataformas de nuestros socios publicitarios (como Facebook, Google y LinkedIn). Se utilizan para medir y servir publicidad en el entorno digital de forma personalizada y pueden ayudar a mostrar anuncios en línea de productos que pueden ser de su interés. Si no se permite el uso de estas cookies, recibirá menos publicidad de nuestras novedades.",
                    },
                    en_US: {
                        nome: "BEHAVIORAL/MARKETING/THIRD PARTY COOKIES",
                        descricao:
                            "Behavioral/marketing/third-party cookies serve to trace the User's preferences based on data relating to their browsing, helping to display and create personalized ads. These cookies can be activated both on our website and on the platforms of our advertising partners (such as Facebook, Google and LinkedIn). They are used to measure and serve advertising in the digital environment in a personalized way and can help show online ads for products that may be of interest to you. If the use of these cookies is not permitted, you will receive less advertising for our news.",
                    },
                },
                itens: [
                    {
                        nome: "_personalizado_",
                        valor: "s51f6ew51few986fe51g65ewg156w1g6e5",
                        descricao: {
                            pt_BR: "",
                            es_ES: "",
                            en_US: "",
                        },
                    },
                    {
                        nome: "_ga_234454335",
                        valor: "s51f6ew51few986fe51g65ewg156w1g6e5",
                        descricao: {
                            pt_BR: "",
                            es_ES: "",
                            en_US: "",
                        },
                    },
                    {
                        nome: "_ga",
                        valor: "s51f6ew51few986fe51g65ewg156w1g6e5",
                        descricao: {
                            pt_BR: "",
                            es_ES: "",
                            en_US: "",
                        },
                    },
                    {
                        nome: "_ga_02",
                        valor: "s51f6ew51few986fe51g65ewg156w1g6e5",
                        descricao: {
                            pt_BR: "",
                            es_ES: "",
                            en_US: "",
                        },
                    },
                    {
                        nome: "_ga_03",
                        valor: "s51f6ew51few986fe51g65ewg156w1g6e5",
                        descricao: {
                            pt_BR: "",
                            es_ES: "",
                            en_US: "",
                        },
                    },
                ],
            },
            {
                nome_cookies: "Cookie_Itens_Vazios",
                traducao: {
                    pt_BR: {
                        nome: "Sem Cookies",
                        descricao: " Sem cookies encontrados",
                    },
                    es_ES: {
                        nome: "Sin Cookies",
                        descricao: "No se encontraron cookies",
                    },
                    en_US: {
                        nome: "No Cookies",
                        descricao: "No cookies found",
                    },
                },
                itens: [],
            },
        ],
    };
    /** CALL COOKIES ACCEPTED */
    new implyCookies.CookiesAceite(params);
}

if (!location.pathname.includes("we/CMS")) {
    if (!("isApp" in sessionStorage)) {
        /** REDUCE LOADING TIME SO THAT GOOGLE ROBOTS DO NOT STAY AIMING LOADING */
        document.body.insertBefore(
            cookiesAceiteImport,
            document.body.childNodes[document.body.childNodes.length - 1]
                .nextSibling
        );
        let time = setInterval(() => {
            if (implyCookies !== undefined) {
                clearInterval(time);
                startFlowCookiesAceite();
            }
        }, 1000);
    }
}
