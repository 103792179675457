<template>
    <div class="sci-addressCard">
        <Column size="20">
            <Label :label="$t_('card_cep')" required />
            <v-text-field
                color="gray"
                v-mask="'#####-###'"
                single-line
                outlined
                v-model="cadastro.end_cep"
                placeholder="00000-000"
                hide-details
                dense
            ></v-text-field>
        </Column>

        <Column size="60">
            <Label :label="$t_('card_meu_endereco')" required />
            <v-text-field
                color="gray"
                single-line
                v-model="cadastro.end_logradouro"
                hide-details
                outlined
                dense
            ></v-text-field>
        </Column>

        <Column size="20">
            <Label :label="$t_('card_numero')" required />
            <v-text-field
                color="gray"
                type="tel"
                v-mask="'###########'"
                single-line
                v-model="cadastro.end_numero"
                hide-details
                outlined
                dense
            ></v-text-field>
        </Column>

        <Column size="50">
            <Label :label="$t_('card_complemento')" />
            <v-text-field
                color="gray"
                v-model="cadastro.end_complemento"
                hide-details
                single-line
                outlined
                dense
            ></v-text-field>
        </Column>

        <Column size="50">
            <Label :label="$t_('card_bairro')" required />
            <v-text-field
                color="gray"
                single-line
                v-model="cadastro.end_bairro"
                hide-details
                outlined
                dense
            ></v-text-field>
        </Column>

        <Column size="30">
            <Label :label="$t_('card_pais')" required />
            <v-select
                v-model="cadastro.end_pais"
                :items="paises"
                item-text="dsc"
                item-value="dsc"
                @change="getEstados($event)"
                outlined
                hide-details
                solo
                flat
                dense
            ></v-select>
        </Column>

        <Column size="30">
            <Label :label="$t_('card_estado')" required />
            <v-select
                v-model="cadastro.end_estado"
                :items="estados.estados"
                item-text="dsc"
                item-value="iso_full"
                @change="getCidades($event)"
                outlined
                hide-details
                solo
                flat
                dense
            ></v-select>
        </Column>

        <Column size="40">
            <Label :label="$t_('card_cidade')" required />
            <v-select
                v-model="cadastro.end_cidade"
                :items="cidades.cidades"
                item-text="dsc"
                item-value="dsc"
                outlined
                hide-details
                solo
                flat
                dense
            ></v-select>
        </Column>

        <Column size="100" class="sci-buttonAddress">
            <Button
                type="cta-primary"
                size="cta-regular"
                :label="$t_('card_confirmar')"
                @click="cadastrarNovo(listaEndereco)"
            />
        </Column>
    </div>
</template>

<script>
import MIXEDS from "@/mixeds/Endereco.mixeds.js";
export default {
    name: "CardEnderecos",
    props: ["editarEnd", "listaEndereco"],
    mixins: [MIXEDS],
    components: {
        Button: () => import("@/ds/components/Button.vue"),
        Column: () => import("@/ds/components/Column.vue"),
        Label: () => import("@/ds/components/Label.vue"),
        Headline: () => import("@/ds/components/Headline.vue"),
    },
    watch: {
        editarEnd(endereco) {
            this.cadastro = Object.assign(this.cadastro, endereco);
        },
    },
    async mounted() {
        if (this.editarEnd) {
            this.cadastro = Object.assign(this.cadastro, this.editarEnd);
        }
        await this.$util.getPaises().then((response) => {
            if (response.data && response.data.result.length > 0) {
                this.paises = response.data.result;
            }
        });
        if (this.cadastro.end_pais) {
            this.getEstados(this.cadastro.end_pais);
            if (this.cadastro.end_estado) {
                setTimeout(() => {
                    this.getCidades(this.cadastro.end_estado);
                }, 500);
            }
        }
    },
};
</script>

<style>
div.v-list-item__title,
.v-application .primary--text,
.v-application .primary--text::before,
.v-application .primary--text::after {
    color: var(--dl-color-black) !important;
    caret-color: var(--dl-color-black) !important;
}
.v-text-field__details {
    color: "gray";
    display: none !important;
    margin: 0;
}
</style>

<style scoped>
.sci-addressCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sci-addressCard .sci-buttonAddress {
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
}
.v-application .primary--text {
    color: var(--dl-color-black) !important;
    caret-color: var(--dl-color-black) !important;
}
.v-text-field__details {
    color: "gray";
    display: none !important;
    margin: 0;
}
</style>
