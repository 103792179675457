
import Vue from 'vue';
import VueI18n from 'vue-i18n'

// IMPORTA O JSON COM A TRADUÇÃO

import pt from './i18n/pt.json';
import en from './i18n/en.json';
import es from './i18n/es.json';

export default class Lang {
    constructor(){

        this.langs = [
            { lang: 'es-ES', img: 'https://cloudfront.eleventickets.com/copa_america_2021/oficial/frontend/langs/es.jpg' }, 
            { lang: 'pt-BR', img: 'https://cloudfront.eleventickets.com/copa_america_2021/oficial/frontend/langs/pt.jpg' }, 
            { lang: 'en-US', img: 'https://cloudfront.eleventickets.com/copa_america_2021/oficial/frontend/langs/en.jpg' }
        ]

        this.lang = 'pt-BR'
        localStorage.setItem('lang', 'pt-BR')

    }

    setLang(lang){

        document.documentElement.lang = lang

        this.lang = lang

        localStorage.setItem('lang', lang)

        let object = window.location.pathname.split('/')
        let url    = lang.substring(0, 2);
        
        if(url == object[1]){
            document.location.pathname = window.location.pathname;
        } else {
            document.location.href = `/${url}/`
        }
    }

    get(){
        if('lang' in localStorage) { 
            return localStorage.getItem('lang')
        }
    }

    getLang(){ // FUNC: getURL()

        if('lang' in localStorage) { 
            return { 
                title: localStorage.getItem('lang').substr(0, 2),
                lang: localStorage.getItem('lang'),
                celular: localStorage.getItem('lang').substr(3) 
            }
        }

        switch(window.location.pathname.split('/')[1]){
            case('es'):
                this.setLang('es-ES')
            break;
            case('en'):
                this.setLang('en-US')
            break;
            default:
            case('pt'):
                this.setLang('pt-BR')
            break;
        }

    }

    _traslater(chave) {

        this.lang = 'lang' in localStorage ? localStorage.getItem('lang') : 'pt-BR'

        if(this.lang){

            let lang = null

            switch(this.lang) {
                case 'en-US':
                    lang = en;
                break;
                case 'es-ES':
                    lang = es;
                break;
                default:
                case 'pt-BR':
                    lang = pt;
                break;

            }

            if(lang['' + chave]){
                return lang['' + chave][0].toUpperCase() +  lang['' + chave].substr(1);
            }
            else {
                return `[${chave}]`;   
            }
        }
    }

}

Vue.prototype.$lang_ = new Lang()

Vue.prototype.$t_ = function(chave) {
    return new Lang()._traslater(chave);   
}

Vue.use(VueI18n);

const messages = {
    'pt-BR': pt,
    'en-US': en,
    'es-ES': es,
};

export const i18n = new VueI18n({
    locale: new Lang().get(), // set locale
    fallbackLocale: 'pt-BR', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
});
