import Util from "@/controllers/Util.controller";
import Vue from "vue";
import {
    otimizacaoHomolog,
    otimizacaoPreProd,
    otimizacaoProd,
} from "../../app.config.json";

// import {getLCP, getFID, getCLS} from 'web-vitals';
let otimizacao = {};
switch (process.env.NODE_ENV) {
    case "development":
        otimizacao = otimizacaoHomolog;
        break;
    case "preproduction":
        otimizacao = otimizacaoPreProd;
        break;
    case "production":
    default:
        otimizacao = otimizacaoProd;
        break;
}

Vue.prototype.$sac = new Vue({
    data() {
        return {
            lib: "imply-desk",
            version: "v1.2.1",
            el: "",
            language: "pt",
        };
    },
    methods: {
        init() {
            // TODO: init

            if (!otimizacao.SAC.status) {
                return;
            }

            // =============================================================================

            var sacImport = document.createElement("script");
            sacImport.type = "text/javascript";
            sacImport.async = true;
            sacImport.defer = true;
            sacImport.src =
                "https://sdk.imply.com/" +
                this.lib +
                "-" +
                this.version +
                ".min.js";

            localStorage.setItem("sdkSacVersion", this.version);
            document.head.append(sacImport);
            //document.body.insertBefore(sacImport, document.body.childNodes[document.body.childNodes.length - 1]);
        },

        async startFlowSac() {
            // TODO: startFlowSac
            if (location.pathname.includes("we/CMS")) {
                // console.log("SAC");
                return;
            }
            const util = new Util();
            const root = await util.getVue();

            let idprojeto = otimizacao.SAC.idProjeto;

            var pessoa = window.$storage.getLocal("session");

            var params = {
                idprojeto: idprojeto,
                lang: localStorage.getItem("lang").substring(0, 2) || "pt",
                corPrimaria: this.$config.dados.corprimaria,
                nome: pessoa ? pessoa.nome : "",
                email: pessoa ? pessoa.email : "",
            };

            if (otimizacao.SAC.status) {
                var sac = new imply.SAC(params);
                this.el = document.querySelector(".sac-imply-button-close");
                document.querySelector("#imply-sac-background").style.zIndex = 999999
            }
        },
        revert() {
            // TODO: revert
        },
    },
    watch: {
        dataLayer: function () {
            for (const i in this.dataLayer) {
                // console.log("DataLayer: " + this.dataLayer[i].eventType);
            }
        },
        el() {
            this.el.addEventListener(
                "click",
                () => {
                    this.el.addEventListener("click", this.revert(), false);
                },
                false
            );
        },
    },
});

Vue.prototype.$sac.init();
