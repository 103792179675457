import configs from "@/../app.config.json"; 

let TITTLE = "";
switch (process.env.NODE_ENV) {
    case "development":
        TITTLE = configs.otimizacaoHomolog.titulo;
        break;
    case "preproduction":
        TITTLE = configs.otimizacaoPreProd.titulo;
        break;
    case "production":
    default:
        TITTLE = configs.otimizacaoProd.titulo;
        break;
}

let prefix = "/";

const linksAntigosApp = ["tickets/:idapresentacao"];
const linksOlds = [];

linksAntigosApp.forEach((item, index) => {
    linksOlds.push({
        path: prefix + item,
        name: "Update" + index,
        props: true,
        params: true,
        redirect: prefix + "update",
    });
});

// const isGamificationActive = () => {
//     return window.$root.$config.$features.GAMIFICATION === 'GAMIFICATION';
// };

// const isParqueActive = () => {
//     return window.$root.$config.$features.PARQUE === 'PARQUE';
// };

export default [
    {
        path: "*",
        name: "Error",
        component: () =>
            import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
        meta: { title: TITTLE + "Error 404", requiresAuth: false },
    },
    //{
    //    path: prefix + 'login/:tipo',
    //    name: 'Login',
    //    props: true,
    //    params: true,
    //    component: () => import(/* webpackChunkName: "login" */ '@/views/onboarding/Login.vue'),
    //    meta: { title: TITTLE + 'Login', requiresAuth: false }
    //},
    {
        path: prefix + "politicas/privacidade",
        name: "Politics",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/politicas/Politics.vue"
            ),
        meta: {
            title: TITTLE + "Política de Privacidade",
            requiresAuth: false,
        },
    },
    {
        path: prefix + "politicas/cookies",
        name: "PoliticsCookies",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/politicas/Politics.vue"
            ),
        meta: {
            title: TITTLE + "Política de Privacidade",
            requiresAuth: false,
        },
    },
    //LOGIN/CADASTRO
    {
        path: prefix + "home",
        name: "Home",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "home" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Home", requiresAuth: true },
    },
    {
        path: prefix + "/",
        name: "Login",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ "@/views/onboarding/Login.vue"
            ),
        meta: { title: TITTLE + "Login", requiresAuth: false },
    },
    {
        path: prefix + "esquecisenha",
        name: "Esquecisenha",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "esqueciSenha" */ "@/views/onboarding/Forgot-password.vue"
            ),
        meta: { title: TITTLE + "Esqueci a senha", requiresAuth: false },
    },
    {
        path: prefix + "novasenha",
        name: "NovaSenha",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "esqueciSenha" */ "@/views/Nova-senha.vue"
            ),
        meta: { title: TITTLE + "Nova senha", requiresAuth: false },
    },
    {
        path: prefix + "estrangeiro",
        name: "CadastroEstrangeiro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "cadastro" */ "@/views/onboarding/Foreigner.vue"
            ),
        meta: { title: TITTLE + "Cadastro Estrangeiro", requiresAuth: false },
    },
    {
        path: prefix + "cadastro/:tipo",
        name: "Cadastro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "cadastro" */ "@/views/onboarding/Signin.vue"
            ),
        meta: { title: TITTLE + "Cadastro", requiresAuth: false },
    },
    //PERFIL
    {
        path: prefix + "hub",
        name: "Hub",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "hub" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Hub Torcedor", requiresAuth: true },
    },
    {
        path: prefix + "perfil",
        alias: [prefix + "hubperfil"],
        name: "Perfil",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil" */ "@/views/Hub.vue"),
        meta: { title: TITTLE + "Hub Perfil", requiresAuth: true },
    },
    {
        path: prefix + "perfil/dados",
        alias: [prefix + "hubperfil/dados"],
        name: "HubDados",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/dados" */ "@/views/profile/Dados.vue"
            ),
        meta: { title: TITTLE + "Hub Dados", requiresAuth: true },
    },
    {
        path: prefix + "perfil/endereco",
        alias: [prefix + "hubperfil/endereco"],
        name: "HubEndereco",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/endereco" */ "@/views/profile/Address.vue"
            ),
        meta: { title: TITTLE + "Hub Endereco", requiresAuth: true },
    },
    {
        path: prefix + "perfil/pedidos",
        alias: [prefix + "hubperfil/pedidos"],
        name: "HubPedidos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/address" */ "@/views/profile/Pedidos-abertos.vue"
            ),
        meta: { title: TITTLE + "Hubpedidos", requiresAuth: true },
    },
    {
        path: prefix + "perfil/pedidosfinalizados",
        name: "HubPedidosFinalizados",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/address" */ "@/views/profile/Pedidos-finalizados.vue"
            ),
        meta: { title: TITTLE + "Hubpedidosfinalizados", requiresAuth: true },
    },
    {
        path: prefix + "perfil/financeiro",
        alias: [prefix + "hubperfil/financeiro"],
        name: "HubFinanceiro",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeiro" */ "@/views/profile/Financeiro-prox.vue"
            ),
        meta: { title: TITTLE + "Hub Financeiro", requiresAuth: true },
    },
    {
        path: prefix + "perfil/financeiropago",
        name: "HubFinanceiroPago",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeiropago" */ "@/views/profile/Financeiro-pago.vue"
            ),
        meta: { title: TITTLE + "Hub Financeiro Pago", requiresAuth: true },
    },
    {
        path: prefix + "perfil/financeirocartao",
        name: "HubFinanceiroCartao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/financeirocartao" */ "@/views/profile/Financeiro-cartao.vue"
            ),
        meta: { title: TITTLE + "Hub Financeiro Cartao", requiresAuth: true },
    },
    //JOGOS
    {
        path: prefix + "campeonatos",
        name: "Campeonatos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/jogos" */ "@/views/Championship.vue"
            ),
        meta: {
            title: TITTLE + "Campeonatos",
            requiresAuth: false,
            breadcrumb: [
                { name: "Início", link: "Hub" },
                { name: "Campeonatos" },
            ],
        },
    },
    {
        path: prefix + "campeonatos/:pagina",
        name: "PartidasCampeonatos",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "perfil/jogos" */ "@/views/Matchs-Championship.vue"
            ),
        meta: {
            title: TITTLE + "Partidas por campeonato",
            requiresAuth: false,
            breadcrumb: [
                { name: "Início", link: "Hub" },
                { name: "Campeonatos", link: "Campeonatos" },
                { name: "Partidas" },
            ],
        },
    },
    {
        path: prefix + "jogos",
        name: "Jogos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil/jogos" */ "@/views/Matchs.vue"),
        meta: {
            title: TITTLE + "Hub Jogos",
            requiresAuth: false,
            breadcrumb: [
                { name: "Início", link: "Hub" },
                { name: "Campeonatos", link: "Campeonatos" },
                { name: "Todos os Jogos" },
            ],
        },
    },
    {
        path: prefix + "jogos/:campeonato/:data/:titulo",
        name: "JogosInfos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "perfil/jogos" */ "@/views/Match.vue"),
        meta: { title: TITTLE + "Tickets", requiresAuth: false },
    },
    {
        path: prefix + "jogosquejafui",
        name: "Jogosjafui",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "jogosjafui" */ "@/views/Past-matchs.vue"
            ),
        meta: { title: TITTLE + "Jogos que ja Fui", requiresAuth: true },
    },
    // {
    //     path: prefix + "jogos/pos-jogo/:data/:titulo/:campeonato",
    //     name: "Posjogo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(/* webpackChunkName: "jogosjafui" */ "@/views/PosPlay.vue"),
    //     meta: { title: TITTLE + "Pós jogo", requiresAuth: false },
    // },
    {
        path: prefix + "jogos-fora/:data/:titulo/:campeonato",
        name: "JogosFora",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "jogosfora" */ "@/views/Match-away.vue"
            ),
        meta: { title: TITTLE + "Jogos Fora", requiresAuth: false },
    },
    //PLANOS
    {
        path: prefix + "planos",
        name: "Planos",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Planos" */ "@/views/Plans.vue"),
        meta: { title: TITTLE + "planos", requiresAuth: true },
        children: [
            {
                path: "p/:plano",
                name: "PlanosSegmentado",
            },
        ],
    },
    {
        path: prefix + "planoscadeira",
        name: "PlanosCadeira",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "PlanosCadeira" */ "@/views/Planos.vue"
            ),
        meta: { title: TITTLE + "Planos cadeira", requiresAuth: true },
    },
    {
        path: prefix + "sersocioacademia",
        name: "SerSocioAcademia",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersocioacademia" */ "@/views/checkout-member/Success-Academia.vue"
            ),
        meta: { title: TITTLE + "Ser Socio Academia", requiresAuth: true },
    },
    {
        path: prefix + "sersocioendereco",
        name: "SerSocioEndereco",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersocioendereco" */ "@/views/checkout-member/Address.vue"
            ),
        meta: { title: TITTLE + "Ser Socio Endereco", requiresAuth: true },
    },
    {
        path: prefix + "sersociopagamento",
        name: "SerSocioPagamento",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersociopagamento" */ "@/views/checkout-member/Payment.vue"
            ),
        meta: { title: TITTLE + "Ser Socio Pagmento", requiresAuth: true },
    },
    {
        path: prefix + "sersocioativacao",
        name: "SerSocioAtivacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersocioativacao" */ "@/views/checkout-member/Activation.vue"
            ),
        meta: { title: TITTLE + "Ser Socio Ativacao", requiresAuth: true },
    },
    {
        path: prefix + "sersociocheckout",
        name: "SerSocioCheckout",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "sersociocheckout" */ "@/views/checkout-member/Success.vue"
            ),
        meta: { title: TITTLE + "Ser Socio Checkout", requiresAuth: true },
    },
    //TICKETS/CARRINHO
    {
        path: prefix + "tickets/:id",
        name: "PaginaTickets",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "tickets" */ "@/views/Match.vue"),
        meta: { title: TITTLE + "Tickets", requiresAuth: true },
    },

    {
        path: prefix + "carrinho/:pagina",
        name: "Cart",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/cart/Cart.vue"),
        meta: { title: TITTLE + "Carrinho", requiresAuth: true },
    },
    {
        path: prefix + "carrinho/sucesso/:pagina",
        name: "CartSuccess",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Carrinho - Sucesso" */ "@/views/cart/Success.vue"
            ),
        meta: { title: TITTLE + "Carrinho - Sucesso", requiresAuth: true },
    },

    {
        path: prefix + "locarcadeira",
        name: "LocarCadeira",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeira" */ "@/views/checkout-seats/Seat.vue"
            ),
        meta: { title: TITTLE + "Locar cadeira", requiresAuth: true },
    },
    {
        path: prefix + "locarcadeirainformacao",
        name: "LocarCadeiraInformacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeirainformacao" */ "@/views/checkout-seats/Confirmation.vue"
            ),
        meta: {
            title: TITTLE + "Locar cadeira Informações",
            requiresAuth: true,
        },
    },
    {
        path: prefix + "locarcadeirapagamento",
        name: "LocarCadeiraPagamento",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeirapagamento" */ "@/views/checkout-seats/Payment.vue"
            ),
        meta: { title: TITTLE + "Locar Cadeira Pagamento", requiresAuth: true },
    },
    {
        path: prefix + "locarcadeiraativacao",
        name: "LocarCadeiraAtivacao",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locarcadeiraativacao" */ "@/views/Locacao-cadeira.vue"
            ),
        meta: { title: TITTLE + "Locar Cadeira Ativação", requiresAuth: true },
    },
    {
        path: prefix + "locacaocheckout",
        name: "PagarLocacaoSucesso",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "locacaocheckout" */ "@/views/checkout-seats/Success-seats.vue"
            ),
        meta: { title: TITTLE + "Locação Sucesso", requiresAuth: true },
    },
    //SERVICES/UTILS
    {
        path: prefix + "webmail/view/:idmail",
        name: "Webmail",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/ViewEmails.vue"),
        meta: { title: TITTLE + "Webmail", requiresAuth: false },
    },
    {
        path: prefix + "ao-vivo",
        name: "Live",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/Live.vue"),
        meta: { title: TITTLE + "Ao vivo", requiresAuth: true },
    },
    //SERVICOS
    {
        path: prefix + "servicos/ajuda",
        name: "Help",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Carrinho" */ "@/views/help/Help.vue"),
        meta: { title: TITTLE + "Ajuda", requiresAuth: false },
    },

    // Desativadas para reter essas infos em uma mesma página
    // {
    //     path: prefix + "servicos/ajuda/cas",
    //     name: "HelpCas",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpCas.vue"
    //         ),
    //     meta: { title: TITTLE + "Ajuda", requiresAuth: true },
    // },
    // {
    //     path: prefix + "servicos/ajuda/sac",
    //     name: "HelpSac",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpSac.vue"
    //         ),
    //     meta: { title: TITTLE + "Ajuda", requiresAuth: true },
    // },
    // {
    //     path: prefix + "servicos/ajuda/ouvidoria",
    //     name: "HelpOuvidoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/help/HelpOmbudsman.vue"
    //         ),
    //     meta: { title: TITTLE + "Ajuda", requiresAuth: true },
    // },


    //ESPECIAIS
    {
        path: prefix + "design-system",
        name: "DesignSystem",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "DesignSystem" */ "@/views/DesignSystem.vue"
            ),
        meta: { title: TITTLE + "Design System", requiresAuth: true },
    },
    {
        path: prefix + "beneficios",
        name: "Benefits",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Benefits.vue"),
        meta: { title: TITTLE + "Benefícios", requiresAuth: true },
    },
    {
        path: prefix + "hubperfil/pedidos",
        name: "Update1",
        props: true,
        params: true,
        redirect: prefix + "update",
    },
    {
        path: prefix + "landpage/:pagina",
        name: "Landpage",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Landingpage.vue"),
        meta: { title: TITTLE + "Landpage", requiresAuth: true },
    },
    {
        path: prefix + "voucher",
        name: "Voucher",
        props: true,
        params: true,
        component: () =>
            import(/* webpackChunkName: "Webmail" */ "@/views/Voucher.vue"),
        meta: { title: TITTLE + "Voucher", requiresAuth: true },
    },

    // Institucionais
    // {
    //     path: prefix + "/",
    //     name: "HomeInstitucional",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(/* webpackChunkName: "BeiraRio" */ "@/views/Home.vue"),
    //     meta: { title: TITTLE + "HomeInstitucional", requiresAuth: false },
    // },

    // NOTÍCIAS
    // {
    //     path: prefix + "noticias",
    //     name: "Noticias",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/News.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Noticias",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Notícias" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "noticias/:category",
    //     name: "NoticiasCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/NewsCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Noticias-categoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Notícias", link: "Noticias" },
    //             { name: "Categoria" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "noticia/:category/:subtitle",
    //     name: "NoticiasSubtitulo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/news/NewsSubtitle.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Noticia-titulo",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Notícias", link: "Noticias" },
    //             { name: "Categoria", link: "NoticiasCategoria" },
    //             { name: "Notícia" },
    //         ],
    //     },
    // },

    // {
    //     path: prefix + "galeria",
    //     name: "Galeria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoGalery.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galeria-fotos",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Galerias" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "galeria/:category",
    //     name: "GaleriaCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galeria-categoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Galerias", link: "Galeria" },
    //             { name: "Categoria" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "galeria/:category/:subtitle",
    //     name: "GaleriaGrupo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/galery/PhotoGroup.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Galeria-grupo",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Galerias", link: "Galeria" },
    //             { name: "Categoria", link: "GaleriaCategoria" },
    //             { name: "Galeria" },
    //         ],
    //     },
    // },


    //PROJETOS
    // {
    //     path: prefix + "projetos-colorados",
    //     name: "ProjetosColorados",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/projetos-colorados/Projects.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Projetos Colorados",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Projetos Colorados" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "projetos-colorados/:category",
    //     name: "ProjetosColoradosCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/projetos-colorados/ProjectsCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "projetos-colorados",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Projetos Colorados", link: "ProjetosColorados" },
    //             { name: "Projeto" },
    //         ],
    //     },
    // },

    // Futebol
    // {
    //     path: prefix + "futebol",
    //     name: "Futebol",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootBall.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "futebol",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Início", link: "Home" }, { name: "Futebol" }],
    //     },
    // },
    // {
    //     path: prefix + "futebol/:category",
    //     name: "FutebolCategoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootballCategory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "futebol-categoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Futebol", link: "Futebol" },
    //             { name: "Categoria" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "futebol/:home/:equip/:category",
    //     name: "FutebolEquipe",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/FootballEquip.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "futebol-equipe",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Futebol", link: "Futebol" },
    //             {
    //                 name: "Categoria",
    //                 link: "FutebolCategoria",
    //                 FutebolCategoria: true,
    //             },
    //             { name: "Equipe" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "futebol/categoria/equipe/atleta/:player",
    //     name: "EquipPlayer",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/football/EquipPlayer.vue"
    //         ),
    //     meta: { title: TITTLE + "equipe-jogador", requiresAuth: false },
    // },

    //Clube
    // {
    //     path: prefix + "clube",
    //     name: "Clube",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Club.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "clube",
    //         requiresAuth: false,
    //         breadcrumb: [{ name: "Início", link: "Home" }, { name: "Clube" }],
    //     },
    // },

    // // Titulos
    // {
    //     path: prefix + "clube/titulos",
    //     name: "HistoriaTitulos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/titles/Titulos.vue"
    //         ),
    //     meta: { title: TITTLE + "Títulos", requiresAuth: false },
    // },
    // {
    //     path: prefix + "clube/titulos/:slug",
    //     name: "ClubTitle",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/titles/ClubTitle.vue"
    //         ),
    //     meta: { title: TITTLE + "Títulos", requiresAuth: false },
    // },
    // // Historia Inicio
    // {
    //     path: prefix + "clube/historia",
    //     name: "Historia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/HistoryClub.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "historia-clube",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "História-Ínicio" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "clube/idolos",
    //     name: "Idolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Idols.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "idolos",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "Ídolos" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "clube/idolos/:player",
    //     name: "IdolPlayer",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/club/Idol-Player.vue"
    //         ),
    //     meta: { title: TITTLE + "Jogador Idolo", requiresAuth: false },
    // },

    // // Simbolos
    // {
    //     path: prefix + "clube/simbolos",
    //     name: "Simbolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Simbolos" */ "@/views/institucionais/simbols/Home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "Simbolos" },
    //         ],
    //     },
    // },
    // // Simbolos - Saci
    // {
    //     path: prefix + "simbolos/saci",
    //     name: "SimbolosSaci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosSaci" */ "@/views/institucionais/simbols/Saci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/saci/:slug",
    //     name: "SaciSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosSaciNascimento" */ "@/views/institucionais/simbols/SaciSlide.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/distintivo",
    //     name: "SimbolosDistintivo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosDistintivo" */ "@/views/institucionais/simbols/Distintivo.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Distintivo",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/distintivo/:slug",
    //     name: "SimbolosDistintivoSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosDistintivo1920" */ "@/views/institucionais/simbols/Distintivo-slide.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Distintivo",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "simbolos/bandeira",
    //     name: "SimbolosBandeira",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "SimbolosBandeira" */ "@/views/institucionais/simbols/Bandeira.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Símbolos Colorados - Bandeira",
    //         requiresAuth: false,
    //     },
    // },
    
    // {
    //     path: prefix + "clube/lista-grenais",
    //     name: "ListaGrenais",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Grenais-List.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Lista de Grenais",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "Lista de Grenais" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "clube/cifra-hino",
    //     name: "CifraHino",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Cifra-hino.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Cifra do Hino",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "Cifra do Hino" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "clube/funcoes-consulado",
    //     name: "FuncoesConsulado",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Funcoes-consulado.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Funções do Consulado",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "Funções do Consulado" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "clube/faixa-gigante",
    //     name: "FaixaGigante",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Faixa-gigante.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Faixa no Gigante",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "Faixa no Gigante" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "clube/consules-culturais",
    //     name: "ConsulesCulturais",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Consules-culturais.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Cônsules Culturais",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "Cônsules Culturais" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "clube/feci",
    //     name: "Feci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Clube" */ "@/views/institucionais/club/Feci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "FECI",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Clube", link: "Clube" },
    //             { name: "FECI" },
    //         ],
    //     },
    // },

    //   BEIRA-RIO
    // {
    //     path: prefix + "beira-rio",
    //     name: "BeiraRio",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/beira-rio/Beira-rioStart.vue"
    //         ),
    //     meta: { title: TITTLE + "Beira-Rio", requiresAuth: false },
    // },
    // {
    //     path: prefix + "beira-rio/:slug",
    //     name: "BeiraRioSlide",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/beira-rio/BeiraRioSlides.vue"
    //         ),
    //     meta: { title: TITTLE + "Beira-Rio", requiresAuth: false },
    // },

    // // DIRETORIA
    // {
    //     path: prefix + "diretoria",
    //     name: "Diretoria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/BoardDirectors.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Diretoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Diretoria" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "diretoria/membros",
    //     name: "DiretoriaMembros",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/institucionais/directors/DirectorMembers.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Membros da diretoria",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Diretoria", link: "Diretoria" },
    //             { name: "Membros" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "diretoria/conselho-fiscal",
    //     name: "ConselhoFiscal",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/FiscalCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Conselho Fiscal",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Diretoria", link: "Diretoria" },
    //             { name: "Conselho Fiscal" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "diretoria/conselho-gestao",
    //     name: "ConselhoGestao",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/ManagementCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Conselho de Gestão",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Diretoria", link: "Diretoria" },
    //             { name: "Conselho de Gestão" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "diretoria/conselho-deliberativo",
    //     name: "ConselhoDeliberativo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/DeliberativeCouncil.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Conselho Deliberativo",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Diretoria", link: "Diretoria" },
    //             { name: "Conselho Deliberativo" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "diretoria/ex-presidentes",
    //     name: "ExPresidentes",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/ExPresidentes.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Ex-presidentes",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Diretoria", link: "Diretoria" },
    //             { name: "Ex-presidentes" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "diretoria/estatuto-clube",
    //     name: "EstatutoClube",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/directors/StatuteClub.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Estatuto do Clube",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Diretoria", link: "Diretoria" },
    //             { name: "Estatuto do Clube" },
    //         ],
    //     },
    // },
    
    // {
    //     path: prefix + "servicos",
    //     name: "Servicos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/institucionais/Servicos.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Serviços",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Serviços" },
    //         ],
    //     },
    // },
    // // PARQUE GIGANTE
    // {
    //     path: prefix + "servicos/parquegigante",
    //     name: "ParqueGigante",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //     /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/ParqueGigante.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Parque Gigante",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Serviços", link: "Servicos" },
    //             { name: "Parque" },
    //         ],
    //         parque: true,
    //     },
    // },
    // {
    //     path: prefix + "servicos/parquegigante/:produto",
    //     name: "ProdutosParque",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Produtos-Parque.vue"
    //      ), 
    //     meta: {
    //         title: TITTLE + "Produto Parque Gigante",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Serviços", link: "Servicos" },
    //             { name: "Parque", link: "ParqueGigante" },
    //             { name: "Produtos" },
    //         ],
    //         parque: true,
    //     },
    // },
    // {
    //     path: prefix + "servicos/parquegigante/produtos/planos",
    //     name: "PlanosParque",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //           /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Plans-park.vue"
    //      ),
    //     meta: {
    //         title: TITTLE + "Planos Parque",
    //         requiresAuth: true,
    //         parque: true,
    //     },
    // },

    // {
    //     path: prefix + "servicos/parquegigante/produtos/reservar-parque",
    //     name: "ReservarPark",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Reservar-park.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "reservar",
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: prefix + "servicos/parquegigante/produtos/reservar-parque/confirmar",
    //     name: "ReservarConcluir",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "ParqueGigante" */ "@/views/ParqueGigante/Reservar-pagamento.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "reservar-confirmar",
    //         requiresAuth: false,
    //     },
    // },

    // MUSEU DO INTER
    // {
    //     path: prefix + "servicos/museu-do-inter",
    //     name: "MuseuDoInter",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Museu do Inter",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Serviços", link: "Servicos" },
    //             { name: "Museu do Inter" },
    //         ],
    //     },
    // },
    // {
    //     path: prefix + "servicos/visita-colorada",
    //     name: "VisitaColorada",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Visita-Colorada.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Visita Colorada",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "servicos/visita-express",
    //     name: "VisitaExpress",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "MuseuDoInter" */ "@/views/museu-do-inter/Visita-Express.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Visita Express",
    //         requiresAuth: false,
    //     },
    // },

    // ESTACIONAMENTO
    // {
    //     path: prefix + "servicos/estacionamento",
    //     name: "Estacionamento",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Estacionamento" */ "@/views/Estacionamento.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Estacionamento",
    //         requiresAuth: false,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Serviços", link: "Servicos" },
    //             { name: "Estacionamento" },
    //         ],
    //     },
    // },

    // GAMIFICATION
    // {
    //     path: prefix + "experiencias",
    //     name: "Gamification",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Gamification.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiências",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Experiências" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/catalogo",
    //     name: "Experiences",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Experiences.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiências - Catálogo de experiências",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Experiências", link: "Gamification" },
    //             { name: "Catálogo" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/ganhe-pontos",
    //     name: "EarnPoints",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Earn-points.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiências - Ganhe pontos",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Experiências", link: "Gamification" },
    //             { name: "Ganhe pontos" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/extrato",
    //     name: "History",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/History.vue"
    //     ),
    //     meta: {
    //         title: TITTLE + "Experiências - Extrato",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Experiências", link: "Gamification" },
    //             { name: "Histórico" },
    //         ],
    //         gamification: true,
    //     },
    // },
    // {
    //     path: prefix + "experiencias/rubros",
    //     name: "Rubros",
    //     props: true,
    //     params: true,
    //     component: () => import(
    //          /* webpackChunkName: "Diretoria" */ "@/views/gamification/Rubros.vue"
    //      ),
    //     meta: {
    //         title: TITTLE + "Experiências - Rubros",
    //         requiresAuth: true,
    //         breadcrumb: [
    //             { name: "Início", link: "Home" },
    //             { name: "Experiências", link: "Gamification" },
    //             { name: "Rubros" },
    //         ],
    //         gamification: true,
    //     },
    // },

    //ADMIN CADASTRO DE CONTEÚDOS
    {
        path: prefix + "we/CMS/cadastrar/conteudos",
        name: "adminRegister",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminView.vue"
            ),
        meta: {
            title: TITTLE + "Cadastro de conteúdos",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/aoVivo",
    //     name: "adminLive",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminLive.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Cadastro de vídeos",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/transparencia",
    //     name: "adminTransparencia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminTransparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da transparência",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/noticias",
    //     name: "adminNoticias",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminNews.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de notícias",
    //         requiresAuth: false,
    //     },
    // },
    {
        path: prefix + "we/CMS/cadastrar/conteudos/banners",
        name: "adminBanners",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBanners.vue"
            ),
        meta: {
            title: TITTLE + "Admin de Banners",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/galeria",
    //     name: "adminGaleria",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminGallerys.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Galeria",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/futebol",
    //     name: "adminFutebol",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminFootball.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Futebol",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/idolos",
    //     name: "adminIdolos",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminIdols.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Ídolos",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/projetos-colorados",
    //     name: "adminProjeto",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminProjetos.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Projetos Colorados",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/beirario",
    //     name: "adminBeiraRio",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBeiraRio.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Beira Rio",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/distintivos",
    //     name: "adminDistintivo",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminDistintivo.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin de Distintivos",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/bandeira",
    //     name: "adminBandeira",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBandeira.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Bandeira",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/historia",
    //     name: "adminHistory",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminHistory.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da História",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/saci",
    //     name: "adminSaci",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminSaci.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Saci",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/diretoria",
    //     name: "adminBoardDirectors",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminBoardDirectors.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Diretoria",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/mais-sobre-clube",
    //     name: "adminMoreAbout",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminMoreAbout.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin da Mais sobre o clube",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/lista-de-grenais",
    //     name: "adminGrenalList",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminGrenalList.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin lista de grenais",
    //         requiresAuth: false,
    //     },
    // },
    {
        path: prefix + "we/CMS/cadastrar/conteudos/servico-de-jogo",
        name: "adminServicoJogo",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "Diretoria" */ "@/views/admin/AdminServicoJogo.vue"
            ),
        meta: {
            title: TITTLE + "Admin Serviço de Jogo",
            requiresAuth: false,
        },
    },
    {
        path: prefix + "we/CMS/cadastrar/conteudos/rotas",
        name: "adminRotas",
        props: true,
        params: true,
        component: () =>
            import(
                /* webpackChunkName: "adminRotas" */ "@/views/admin/AdminRotas.vue"
            ),
        meta: {
            title: TITTLE + "Admin de Rotas",
            requiresAuth: false,
        },
    },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/parque",
    //     name: "adminParque",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "adminparque" */ "@/views/admin/AdminParque.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Parque",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "we/CMS/cadastrar/conteudos/museu",
    //     name: "adminMuseu",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "adminMuseu" */ "@/views/admin/AdminMuseu.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Admin do Museu",
    //         requiresAuth: false,
    //     },
    // },


    // PORTAL DA TRANSPARÊNCIA
    // {
    //     path: prefix + "portal-da-transparencia",
    //     name: "portalTransparencia",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "Carrinho" */ "@/views/transparencia/Portal-transparencia-home.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparencia",
    //         requiresAuth: false,
    //     },
    // },
    // // TRANSPARENCIA
    // { 
    //     path:
    //         prefix +
    //         "portal-da-transparencia/informativos-financeiro/:sub_menu",
    //     name: "TransparenciaSubMenu",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparência",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "portal-da-transparencia/institucional/:sub_menu",
    //     name: "TransparenciaInstitucional",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparência",
    //         requiresAuth: false,
    //     },
    // },
    // {
    //     path: prefix + "portal-da-transparencia/programas-de-gestao/:sub_menu",
    //     name: "TransparenciaGestao",
    //     props: true,
    //     params: true,
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "BeiraRio" */ "@/views/transparencia/Transparencia.vue"
    //         ),
    //     meta: {
    //         title: TITTLE + "Portal da Transparência",
    //         requiresAuth: false,
    //     },
    // },

    ...linksOlds,
];
