import { render, staticRenderFns } from "./Card-endereco.vue?vue&type=template&id=08b7f337&scoped=true"
import script from "./Card-endereco.vue?vue&type=script&lang=js"
export * from "./Card-endereco.vue?vue&type=script&lang=js"
import style0 from "./Card-endereco.vue?vue&type=style&index=0&id=08b7f337&prod&lang=css"
import style1 from "./Card-endereco.vue?vue&type=style&index=1&id=08b7f337&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b7f337",
  null
  
)

export default component.exports